'use strict';
define([], function () {
    var PATTERNS = {
        cf: /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/i,
        destinationCode: /[a-zA-Z0-9]{7}/,
        vat: /^((AT)U[0-9]{8}|(BE)0[0-9]{9}|(BG)[0-9]{9,10}|(CY)[0-9]{8}[A-Z]|(CZ)[0-9]{8,11}|(DE)[0-9]{9}|(DK)[0-9]{8}|(EE)[0-9]{9}|(EL|GR)[0-9]{9}|(ES)[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)[0-9]{8}|(FR)[0-9A-Z]{2}[0-9]{9}|(GB)([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)[0-9]{8}|(IE)[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)([0-9]{9}|[0-9]{12})|(LU)[0-9]{8}|(LV)[0-9]{11}|(MT)[0-9]{8}|(NL)[0-9]{9}B[0-9]{2}|(PL)[0-9]{10}|(PT)[0-9]{9}|(RO)[0-9]{2,10}|(SE)[0-9]{12}|(SI)[0-9]{8}|(SK)[0-9]{10})$/i,
        email: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    };

    function Controller($scope, __env, $timeout, $state, $stateParams, UserService, CountriesService, TaxService, CheckoutService, plan, infoUser, isTrialing, $translate, coupon, $window, ItalyService, $cookies, $q, TrackEventService, $mdMedia, AuthService, PlanDetailsService, UsetifulService, LanguageService, CRMService) {
        var vm = $scope;
        vm.patterns = PATTERNS;
        var default_country = 'US';
        if ($translate.instant('currentLangCode').indexOf('it') !== -1) default_country = 'IT';
        var referral = $cookies.get('storeferral');

        removePromoCouponIfExpired();

        vm.steps = {
            country: {
                show: false, exist: false, hide: true
            }, details: {
                show: true, exist: true, hide: false
            }, payment: {
                show: false, exist: false, hide: false
            }
        };

        vm.loading = {
            payment: false
        };
        vm.isTrialing = isTrialing;
        vm.filterData = {
            trialEndOn: getDateSubEnd(plan, isTrialing), subscriptionEndAt: getDateSubEnd(plan, isTrialing)
        };
        vm.trialEndOn = getDateSubEnd(plan, isTrialing);
        vm.italy = ItalyService.all();
        vm.requiredVat = false;
        vm.requiredBusinessName = false;
        vm.afterPaidLoading = false;
        vm.errorVat = false;
        vm.plan = plan;
        vm.plan.amountDecimal = castToPrice(vm.plan.amount);
        vm.totalCheckout = 0;
        vm.tax = 0;
        vm.$mdMedia = $mdMedia;
        vm.hasCard = null;
        vm.countries = CountriesService;
        vm.hasOrderTax = hasOrderTax;
        vm.unsetCoupon = unsetCoupon;
        vm.goToBillingDetails = goToBillingDetails;

        /**
         * Check Exist on URL the params Deal Id
         */
        if ($stateParams.dealId) {
            checkOnLocalStorage('dealId', $stateParams.dealId);
        }

        if ($stateParams.saleOwner) {
            checkOnLocalStorage('saleOwner', $stateParams.saleOwner);
        }

        vm.isAuthenticated = AuthService.isAuthenticated();

        if (vm.isAuthenticated) {
            var user = AuthService.getSessionUser();
            vm.userId = user.id;
            vm.currentUserEmail = user.email;
        }

        vm.authUser = {
            email: null, pass: null, checkClauses: null,
        };

        vm.order = {
            coupon: coupon.name || null, cart: [{
                planAlias: $stateParams.alias, qty: 1
            }], paymentMethod: null, billing: {
                billingName: infoUser && infoUser.billingName,
                billingFirstname: infoUser && (infoUser.billingFirstname || infoUser.firstname),
                billingLastname: infoUser && (infoUser.billingLastname || infoUser.lastname),
                billingBusinessName: infoUser && infoUser.billingBusinessName,
                billingVat: infoUser && infoUser.billingVat,
                billingCf: infoUser && infoUser.billingCf,
                billingIsEU: infoUser && infoUser.billingIsEU,
                billingPhone: infoUser && infoUser.billingPhone,
                billingAddressCity: infoUser && infoUser.billingAddressCity,
                billingAddressLine1: infoUser && infoUser.billingAddressLine1,
                billingAddressLine2: infoUser && infoUser.billingAddressLine2,
                billingAddressState: infoUser && infoUser.billingAddressState,
                billingAddressZip: infoUser && infoUser.billingAddressZip,
                billingAddressCountry: (infoUser && infoUser.billingAddressCountry) || default_country,
                billingPec: infoUser && infoUser.billingPec,
                billingDestinationCode: infoUser && infoUser.billingDestinationCode,
            }, transaction: null, amount: vm.totalCheckout, currency: 'eur', tax: vm.tax, referral: referral || null
        };

        vm.init = function () {
            vm.isCompany = !!(vm.order && vm.order.billing && (vm.order.billing.billingBusinessName || vm.order.billing.billingVat));
        };

        vm.resetCompanyBillingInfo = function () {
            vm.order.billing.billingBusinessName = null;
            vm.order.billing.billingVat = null;
            vm.order.billing.billingPec = null;
            vm.order.billing.billingDestinationCode = null;
        };

        vm.applyCoupon = applyCoupon;
        vm.$watch('order.billing.billingAddressCountry', function (addressCountry) {
            vm.isItalian = addressCountry.toLowerCase() === 'it';
        });
        vm.$watch('order.billing.billingCf', function (billingCf) {
            if (vm.order && vm.order.billing && vm.order.billing.billingCf) {
                vm.order.billing.billingCf = vm.order.billing.billingCf.toUpperCase();
            }
        });
        vm.$watchGroup(['order.billing.billingFirstname', 'order.billing.billingLastname'], setFullname);
        vm.$watchCollection('coupon.data', getTotal);

        function updateTotal() {
            getTotal();
            if (!vm.isItalian) {
                vm.order.billing.billingAddressState = null;
                vm.order.billing.billingCf = null;
            }

            if (!vm.countries.isEU(vm.order.billing.billingAddressCountry)) {
                //vm.order.billing.billingVat = null;
            }

            if (!vm.isItalian) {
                vm.order.billing.billingPec = null;
            }

            if (!vm.isItalian) {
                vm.order.billing.billingDestinationCode = null;
            }
        }

        vm.$watch('isCompany', updateTotal);
        vm.$watchCollection('order.billing', updateTotal);

        vm.coupon = {
            hide: false,
            loading: false,
            error: null,
            data: coupon.data || null,
            discount: coupon.data ? (coupon.data.amount_off ? castToPrice(coupon.data.amount_off) : castToPrice((plan.amount * (coupon.data.percent_off_precise / 100)).toFixed(0))) : 0
        };

        /**
         * Retrieve if exist the value in localstorage
         * otherwise we set on local Storage new one
         * @param {string} key
         * @param {string} value
         */
        function checkOnLocalStorage(key, value) {
            // If key exist on url we check if it same on localstorage
            // if not same update it
            var valueSaved = $window.localStorage.getItem(key);

            if (valueSaved && valueSaved > 0 && valueSaved !== '0' && valueSaved !== '') {
                $window.localStorage.removeItem(key);
                $window.localStorage.setItem(key, value);
            } else {
                // If not exist we add new value on local storage
                $window.localStorage.setItem(key, value);
            }
        }

        function signUser() {
            // Check if user is logged
            if (AuthService.isAuthenticated()) {
                var userId = null;

                if (infoUser && infoUser.id) {
                    userId = infoUser.id;
                }

                vm.userId = userId;
                return $q.resolve(userId);
            }

            // Check credentials
            if (!vm.authUser.email || !vm.authUser.pass) {
                return $q.reject(new Error('invalid input params'));
            }

            var dfd = $q.defer();

            // Try to login user if he is not logged
            AuthService.checkSignin({
                email: vm.authUser.email, pass: vm.authUser.pass,
            })
                .then(function () {
                    return AuthService.signin({
                        email: vm.authUser.email, pass: vm.authUser.pass,
                    });
                })
                .then(function (user) {
                    var userId;

                    // User exist
                    vm.isAuthenticated = true;
                    if (user && user.id) {
                        userId = user.id;
                    }

                    vm.userId = userId;
                    dfd.resolve(userId);
                })
                .catch(function (err) {
                    // Is password wrong
                    if (err && err.message && err.message === 'incorrect password') {
                        dfd.reject(new Error('incorrect password'));
                        return;
                    }

                    // Check if user can register an account
                    AuthService.checkSignup({
                        email: vm.authUser.email, pass: vm.authUser.pass,
                    })
                        .then(function () {
                            return AuthService.signup({
                                email: vm.authUser.email, pass: vm.authUser.pass,
                            });
                        })
                        .then(function () {
                            return AuthService.signin({
                                email: vm.authUser.email, pass: vm.authUser.pass,
                            });
                        })
                        .then(function (user) {
                            var userId;

                            vm.isAuthenticated = true;
                            if (user && user.id) {
                                userId = user.id;
                            }

                            vm.userId = userId;
                            dfd.resolve(userId);
                        })
                        .catch(function (err) {
                            dfd.reject(err);
                        });
                });
            return dfd.promise;
        }

        vm.moneyApproximation = function (num, fixed) {
            var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
            return num.toString().match(re)[0];
        };

        function unsetCoupon() {
            vm.coupon.data = null;
            vm.order.coupon = null;
            coupon = null;
            $window.localStorage.removeItem('coupon');
        }

        function applyCoupon() {
            vm.steps.details.show = false;
            vm.steps.details.exist = false;
            vm.steps.payment.hide = false;
            vm.steps.details.loading = true;

            var deferred = $q.defer();
            if (!vm.order.coupon) {
                deferred.resolve(true);
            }

            if (vm.order.coupon && vm.order.coupon.length) {
                vm.coupon.error = null;
                vm.coupon.loading = true;
                CheckoutService.checkCoupon({
                    code: vm.order.coupon
                })
                    .then(function (res) {
                        $window.localStorage.setItem('coupon', vm.order.coupon);
                        vm.coupon.loading = false;
                        vm.coupon.data = res.data;
                        vm.coupon.discount = vm.coupon.data.amount_off ? castToPrice(vm.coupon.data.amount_off) : castToPrice((plan.amount * (vm.coupon.data.percent_off_precise / 100))
                            .toFixed(0));
                        deferred.resolve(true);
                    })
                    .catch(function (res) {
                        vm.coupon.loading = false;
                        vm.coupon.error = res.data.error;
                        deferred.resolve(true);
                    });
            }

            return deferred.promise;
        }

        function getDateTrialEnd(plan) {
            var someDate = new Date();
            var numberOfDaysToAdd = plan.trialPeriodDays || 7;
            someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
            var dd = someDate.getDate();
            var mm = someDate.getMonth() + 1;
            var y = someDate.getFullYear();
            return dd + '/' + mm + '/' + y;
        }

        function getDateSubEnd(plan, isTrial) {
            if (isTrial) {
                vm.dateTrialEnd = getDateTrialEnd(plan);
                return vm.dateTrialEnd;
            }

            var someDate = new Date();
            if (plan.interval === 'year') {
                var d = new Date();
                var year = d.getFullYear();
                var month = d.getMonth();
                var day = d.getDate();
                someDate = new Date(year + 1, month, day);
            } else {
                var numberOfDaysToAdd = 30;
                someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
            }
            var dd = someDate.getDate();
            var mm = someDate.getMonth() + 1;
            var y = someDate.getFullYear();
            return dd + '/' + mm + '/' + y;
        }

        vm.onFail = function (message) {
            TrackEventService.event('Funnel Events', 'Payment Failed');
            $state.go('upgrade.failed', {
                alias: $stateParams.alias,
                message: message,
            });
        };

        /**
         *
         * @returns {*}
         */
        vm.onApprove = function () {
            if (!AuthService.isAuthenticated()) {
                $window.console.log(new Error('User not logged in'));
                return;
            }

            var dfd = $q.defer();
            UserService.updateProfile(vm.order.billing)
                .then(function () {
                    vm.loading.payment = true;
                    dfd.resolve();
                })
                .catch(function (err) {
                    dfd.reject(err);
                });

            return dfd.promise;
        };

        /**
         *
         * @param source
         */
        function getCheckoutSession(source) {
            if (!AuthService.isAuthenticated()) {
                $window.console.log(new Error('User not logged in'));
                return;
            }

            vm.loading.payment = true;
            vm.afterPaidLoading = true;
            vm.order.transaction = source;

            UserService.updateProfile(vm.order.billing)
                .then(function () {
                    vm.loading.payment = true;
                    return CheckoutService.getCheckoutSession({
                        order: vm.order
                    });
                })
                .then(function (res) {
                    console.log('getCheckoutSession response', res);

                    if(!res || !res.data || !res.data.data || !res.data.data.sessionLink) {
                        if(res.data.data.error) {
                            vm.onFail(res.data.data.error);
                            return;
                        }

                        vm.onFail('Invalid payment');
                    }

                    CheckoutService.saveCouponOnHistory(vm.order.coupon);
                    $window.location.href = res.data.data.sessionLink;
                })
                .catch(function (err) {
                    vm.onFail(err.message);
                });
        }

        /**
         * @returns {{amount: *, subtotal: *, taxPercent: number, tax: number}}
         */
        function getTotal() {
            vm.order.billing.billingIsEU = CountriesService.isEU(vm.order.billing.billingAddressCountry);
            vm.errorVat = false;
            var toCharge = plan.amount;
            try {
                if (vm.coupon.data && vm.coupon.data.percent_off_precise) {
                    toCharge -= (plan.amount * (vm.coupon.data.percent_off_precise / 100)).toFixed(0);
                } else if (vm.coupon.data && vm.coupon.data.amount_off) {
                    toCharge -= vm.coupon.data.amount_off;
                }
            } catch (err) {
            }

            var subtotal = toCharge;
            var taxPercent = 0;
            var taxSupply = 0;

            var tax = TaxService.getTax(vm.order.billing.billingAddressCountry, vm.countries.isEU(vm.order.billing.billingAddressCountry), vm.isCompany);

            if (tax > 0) {
                taxPercent = tax / 100;
                vm.thisTax = tax;
                taxSupply = subtotal * taxPercent;
                taxSupply = castIntegerPrice(taxSupply) <= 0 ? 0 : castIntegerPrice(taxSupply);
            } else {
                vm.thisTax = 0;
            }

            toCharge = toCharge + taxSupply;
            vm.totalCheckout = castToPrice(toCharge) <= 0 ? 0 : castToPrice(toCharge);
            vm.subtotalCheckout = castToPrice(subtotal) <= 0 ? 0 : castToPrice(subtotal);
            vm.tax = castToPrice(taxSupply);

            return {
                tax: taxSupply, taxPercent: taxPercent, subtotal: subtotal, amount: toCharge
            };
        }

        function hasOrderTax() {
            return vm.isItalian || (!vm.isItalian && (!vm.order.billing.billingBusinessName || vm.order.billing.billingBusinessName.trim() === ''));
        }

        function setFullname() {
            var lastname = vm.order.billing.billingLastname || '';
            var firstname = vm.order.billing.billingFirstname || '';
            vm.order.billing.billingName = (firstname + " " + lastname).trim();
        }

        function castToPrice(x) {
            return parseFloat((x / 100).toFixed(2)).toFixed(2);
        }

        function castIntegerPrice(x) {
            return parseInt(parseFloat(x).toFixed(0));
        }

        function goToBillingDetails() {
            transitionToBillingDetails();
        }

        function transitionToBillingDetails() {
            vm.steps.country.show = false;
            vm.steps.country.exist = false;
            vm.steps.details.hide = false;
            $timeout(function () {
                vm.steps.details.exist = true;
                vm.steps.details.show = true;
            }, 400);
        }

        vm.resetPayment = resetPayment;

        function resetPayment() {
            vm.order.paymentMethod = null;
        }

        vm.logout = function () {
            AuthService.logout();

            $window.setTimeout(function () {
                $state.go($state.current, {}, {
                    reload: true
                });
            }, 200);
        };

        vm.goToPayment = function () {
            if (!vm.billingForm.$valid) {
                return;
            }

            var dfd = $q.defer();

            console.log('Start to signin');
            signUser()
                .then(function () {
                    console.log('Get checkout session');
                    return getCheckoutSession();
                })
                .then(function () {
                    TrackEventService.event('Funnel Events', 'Open Stripe Dialog', plan.alias);
                    TrackEventService.gaV2Event('open_stripe_dialog');
                    dfd.resolve();
                })
                .catch(function (err) {
                    console.log(err);

                    if (err.message) {
                        if (err.message === 'password too short') {
                            vm.billingForm.pass.$setValidity('minlength', false);
                        }

                        if (err.message === 'incorrect password') {
                            vm.billingForm.pass.$setValidity('incorrect-password', false);
                        }
                    }

                    dfd.reject(err);
                });

            return dfd.promise;
        };

        vm.removePasswordError = function () {
            vm.billingForm.pass.$setValidity('incorrect-password', true);

            if (vm.authUser.pass && vm.authUser.pass.length >= 8) {
                vm.billingForm.pass.$setValidity('minlength', true);
            }
        };

        function removePromoCouponIfExpired() {
            var promo = PlanDetailsService.getCurrentPromo();
            if (promo && promo.promoCoupon && $window.localStorage.getItem('coupon') === promo.promoCoupon) {
                $window.localStorage.removeItem('coupon');
            }
        }

        if (vm.isAuthenticated) {
            CRMService.addEvents(['UserToTrialGenericCheckoutRecoverEvent'], [
                'UserToTrialPricingRecoverEvent',
            ]);
        }
    }

    Controller.$inject = ['$scope', '__env', '$timeout', '$state', '$stateParams', 'UserService', 'CountriesService', 'TaxService', 'CheckoutService', 'plan', 'info', 'isTrialing', '$translate', 'coupon', '$window', 'ItalyService', '$cookies', '$q', 'TrackEventService', '$mdMedia', 'AuthService', 'PlanDetailsService', 'UsetifulService', 'LanguageService', 'CRMService'];

    return Controller;
});
