'use strict';
define(['app'], function (app) {
    app.service('PlanDetailsService', PlanDetailsService);
    PlanDetailsService.$inject = ['$state', '$interval'];

    function PlanDetailsService($state, $interval) {
        var self = this;

        /**
         *
         * NB: l'array promoStack serve ad impilare oggetti di tipo Promo, in maniera da gestire più promozioni consecutive senza l'intervento umano
         * si prega di scegliere la startPromoDate e la endPromoDate in maniera sensata e realistica, un piccolo algortitmo ne farà uso
         * per capire se c'è una promozione attiva, e se si, qual è.
         *
         * NB2: attualmente esistono 2 tipi di promo,
         * le 'all-platform-promo' comportano modifiche a tutta la piattaforma compreso pricing e onboardiong
         * le 'only-dashboard-promo' comportano modifiche solo alla dashboard e alla topbar
         *
         * NB3: la classe Date salta un mese, dunque Gennaio = 0, Febbraio = 1, ecc..
         *
         */
        self.promoStack = [
            {
                name: "Secret Friday Promo",
                promoType: 'only-dashboard-promo',
                startPromoDate: new Date(2023, 10, 2, 0, 0, 0),
                endPromoDate: new Date(2023, 10, 14, 23, 59, 59), //real secret friday end-date
                promoCoupon: '',
                currentPromoModifierClass: 'black-friday-special',
                promoImgFile: 'secretfriday2022.svg',
                promoAlt: 'secret friday 2023',
                bannerCTAButtonText: 'Iscriviti in lista d’attesa',
                bannerCTALink: 'https://seotesteronline.typeform.com/secret-friday/?utm_source=suite&utm_campaign=secret-friday',
                bannerCTADescription: 'La <span>Promo Segreta</span> che non puoi <br> perdere per <span> ottimizzare la SEO!</span>',
                bannerLabelText: 'SCOPRI LA PROMO SEGRETA',
                languageVisibility: 'it'
            },
            {
                name: "Black Friday Promo",
                promoType: 'all-platform-promo',
                //startPromoDate: Date.now(),
                startPromoDate: new Date(2023, 10, 15, 0, 0, 0), //real black friday start-date
                endPromoDate: new Date(2023, 10, 26, 23, 59, 59), //real black friday end-date
                promoCoupon: 'BLACKFRIDAY2023',
                currentPromoModifierClass: 'black-friday-special',
                promoImgFile: 'blackfriday2022.svg',
                promoAlt: 'black friday 2023',
                languageVisibility: 'all'
            },
            {
                name: "Cyber Monday Promo",
                promoType: 'all-platform-promo',
                startPromoDate: new Date(2023, 10, 27, 0, 0, 0), //real cyber-monday start-date
                endPromoDate: new Date(2023, 11, 3, 23, 59, 59), //real cyber-monday end-date
                promoCoupon: 'CYBERMONDAY2023',
                currentPromoModifierClass: 'black-friday-special',
                promoImgFile: 'cybermonday2022.svg',
                promoAlt: 'cyber monday 2023',
                languageVisibility: 'all'
            },
            {
                name: "Christmas Promo",
                promoType: 'all-platform-promo',
                startPromoDate: new Date(2023, 11, 13, 0, 0, 0), //real cyber-monday start-date
                endPromoDate: new Date(2023, 11, 20, 23, 59, 59), //real cyber-monday end-date
                promoCoupon: 'CHRISTMASPROMO2023',
                currentPromoModifierClass: 'christmas-promo',
                promoImgFile: 'christmaspromo.svg',
                // promoImgFile: 'cybermonday2022.svg',
                promoAlt: 'Christmas Promo 2023',
                languageVisibility: 'all'
            },
        ];

        /*
        if (!isPromoTime()) {
            var interval = $interval(function () {
                if (isPromoTime()) {
                    $state.reload();
                    $interval.cancel(interval);
                }

            }, 10000);
        }
        */

        self.promoPlans = {
            'Lite': {
                annualAmount: 9,
                monthlyAmount: 13,
                annualInsteadAmount: 26,
                monthlyInsteadAmount: 26,
                annualAmountSavedForYear: 204,
                monthlyAmountSavedForYear: 156,
            },
            'Pro': {
                annualAmount: 13,
                monthlyAmount: 19,
                annualInsteadAmount: 38,
                monthlyInsteadAmount: 38,
                annualAmountSavedForYear: 300,
                monthlyAmountSavedForYear: 228,
            },
            'Plus': {
                annualAmount: 19,
                monthlyAmount: 28,
                annualInsteadAmount: 56,
                monthlyInsteadAmount: 56,
                annualAmountSavedForYear: 444,
                monthlyAmountSavedForYear: 336,
            },
            'Advanced': {
                annualAmount: 24,
                monthlyAmount: 38,
                annualInsteadAmount: 72,
                monthlyInsteadAmount: 72,
                annualAmountSavedForYear: 576,
                monthlyAmountSavedForYear: 432,
            },
            'Premium': {
                annualAmount: 48,
                monthlyAmount: 72,
                annualInsteadAmount: 144,
                monthlyInsteadAmount: 144,
                annualAmountSavedForYear: 1152,
                monthlyAmountSavedForYear: 864,
            },
            'Infinity': {
                annualAmount: 148,
                monthlyAmount: 222,
                annualInsteadAmount: 444,
                monthlyInsteadAmount: 444,
                annualAmountSavedForYear: 3352,
                monthlyAmountSavedForYear: 2664,
            }
        };

        self.plans = [
            {
                name: 'Lite',
                type: 'Personal',
                runningOut: false,
                annualInsteadAmount: 0,
                monthlyInsteadAmount: 0,
                annualAmount: 18,
                monthlyAmount: 26,
                annualAmountSavedForYear: 96,
                monthlyAmountSavedForYear: 0,
                trialPeriodDays: 7,
                annualAlias: 'plan-lite-annual',
                monthlyAlias: 'plan-lite-monthly',
                features: [{
                    platform: {
                        users: 1,
                        projects: 1
                    },
                    seoAnalysis: {
                        analisysPerDay: 20,
                        onPageSeoCheck: true,
                        keywordReasearch: true,
                        contentAnalysis: true,
                        serpCompetitorAnalysis: true,
                    },
                    seoCopywritingAssistant: {
                        accessToTheTool: false,
                        suggestionsWithAI: false,
                        contentGeneration: false,
                    },
                    ai: {
                        accessToTheTool: false,
                        aiCredits: false,
                    },
                    onSiteSeoAudit: {
                        crawlablePagesPerMonth: 250,
                        pagesCanBeAnalyzedByProject: 50,
                        dataRetention: '1 Month'
                    },
                    keywordResearch: {
                        resultsPerKeyword: 150,
                        keywordPerDomainPerMonth: 150,
                        keywordList: 2
                    },
                    backlink: {
                        accessToTheTool: false,
                        backlinkRowsPerMonth: false,
                        analisysPerMonth: false,
                    },
                    positionMonitoring: {
                        trackedKeyword: 25,
                        updateFrequency: '7 Days',
                        searchEngines: 'Google / Yahoo! Bing / Yandex',
                        mobileRanking: true,
                    },
                    report: {
                        exportCsvPerDay: false,
                        dowloadableReport: false,
                        whiteLabelSeoReports: false,
                    },
                    leadGeneration: {
                        formAllowed: 'Infinity',
                        leadIncludedPerMonth: false,
                        leadGenWhitelabelReports: false,
                    },
                    writeAssistant: {
                        accessToTheTool: false,
                        suggestionsWithAI: false,
                        contentGeneration: false,
                    },
                },],
                textualTarget: "for.those.who.want.to.start.with.seo",
                textualFeatures: [
                    "on.page.seo.audit",
                    "keyword.research",
                    "1.seo.project",
                    ""
                ]
            },
            {
                name: 'Pro',
                type: 'Personal',
                runningOut: false,
                annualInsteadAmount: 0,
                monthlyInsteadAmount: 0,
                annualAmount: 26,
                monthlyAmount: 38,
                annualAmountSavedForYear: 144,
                monthlyAmountSavedForYear: 0,
                trialPeriodDays: 7,
                annualAlias: 'plan-pro-annual',
                monthlyAlias: 'plan-pro-monthly',
                features: [{
                    platform: {
                        users: 1,
                        projects: 2
                    },
                    seoAnalysis: {
                        analisysPerDay: 100,
                        onPageSeoCheck: true,
                        keywordReasearch: true,
                        contentAnalysis: true,
                        serpCompetitorAnalysis: true,
                    },
                    seoCopywritingAssistant: {
                        accessToTheTool: true,
                        suggestionsWithAI: true,
                        contentGeneration: true,
                    },
                    ai: {
                        accessToTheTool: true,
                        aiCredits: 60,
                    },
                    onSiteSeoAudit: {
                        crawlablePagesPerMonth: 2500,
                        pagesCanBeAnalyzedByProject: 500,
                        dataRetention: '3 Month'
                    },
                    keywordResearch: {
                        resultsPerKeyword: 1500,
                        keywordPerDomainPerMonth: 1500,
                        keywordList: 10
                    },
                    backlink: {
                        accessToTheTool: true,
                        backlinkRowsPerMonth: 25000,
                        analisysPerMonth: 100,
                    },
                    positionMonitoring: {
                        trackedKeyword: 150,
                        updateFrequency: '7 Days',
                        searchEngines: 'Google / Yahoo! Bing / Yandex',
                        mobileRanking: true,
                    },
                    report: {
                        exportCsvPerDay: 10,
                        dowloadableReport: 5,
                        whiteLabelSeoReports: false,
                    },
                    leadGeneration: {
                        formAllowed: 'Infinity',
                        leadIncludedPerMonth: 10,
                        leadGenWhitelabelReports: false,
                    },
                },],
                textualTarget: "for.smb.and.startup",
                textualFeatures: [
                    "all.the.lite.features",
                    "extended.limits",
                    "seo.content.analysis",
                    "seo.reports"
                ]
            },
            {
                name: 'Plus',
                type: 'Personal',
                runningOut: true,
                annualInsteadAmount: 0,
                monthlyInsteadAmount: 0,
                annualAmount: 38,
                monthlyAmount: 56,
                annualAmountSavedForYear: 216,
                monthlyAmountSavedForYear: 0,
                trialPeriodDays: 7,
                annualAlias: 'plan-plus-annual',
                monthlyAlias: 'plan-plus-monthly',
                features: [{
                    platform: {
                        users: 1,
                        projects: 5
                    },
                    seoAnalysis: {
                        analisysPerDay: 200,
                        onPageSeoCheck: true,
                        keywordReasearch: true,
                        contentAnalysis: true,
                        serpCompetitorAnalysis: true,
                    },
                    seoCopywritingAssistant: {
                        accessToTheTool: true,
                        suggestionsWithAI: true,
                        contentGeneration: true,
                    },
                    ai: {
                        accessToTheTool: true,
                        aiCredits: 300,
                    },
                    onSiteSeoAudit: {
                        crawlablePagesPerMonth: 10000,
                        pagesCanBeAnalyzedByProject: 2000,
                        dataRetention: '3 Month'
                    },
                    keywordResearch: {
                        resultsPerKeyword: 5000,
                        keywordPerDomainPerMonth: 5000,
                        keywordList: 25
                    },
                    backlink: {
                        accessToTheTool: true,
                        backlinkRowsPerMonth: 50000,
                        analisysPerMonth: 200,
                    },
                    positionMonitoring: {
                        trackedKeyword: 500,
                        updateFrequency: '7 Days',
                        searchEngines: 'Google / Yahoo! Bing / Yandex',
                        mobileRanking: true,
                    },
                    report: {
                        exportCsvPerDay: 50,
                        dowloadableReport: 25,
                        whiteLabelSeoReports: true,
                    },
                    leadGeneration: {
                        formAllowed: 'Infinity',
                        leadIncludedPerMonth: 20,
                        leadGenWhitelabelReports: true,
                    }
                },],
                textualTarget: "for.seo.consultant",
                textualFeatures: [
                    "all.the.pro.features",
                    "extended.limits",
                    "white.label.reports",
                    "5.seo.projects"
                ]
            },
            {
                name: 'Advanced',
                type: 'Agency',
                runningOut: true,
                annualInsteadAmount: 0,
                monthlyInsteadAmount: 0,
                annualAmount: 48,
                monthlyAmount: 72,
                annualAmountSavedForYear: 288,
                monthlyAmountSavedForYear: 0,
                trialPeriodDays: 7,
                annualAlias: 'plan-advanced-annual',
                monthlyAlias: 'plan-advanced-monthly',
                features: [{
                    platform: {
                        users: 5,
                        projects: 15
                    },
                    seoAnalysis: {
                        analisysPerDay: 500,
                        onPageSeoCheck: true,
                        keywordReasearch: true,
                        contentAnalysis: true,
                        serpCompetitorAnalysis: true,
                    },
                    seoCopywritingAssistant: {
                        accessToTheTool: true,
                        suggestionsWithAI: true,
                        contentGeneration: true,
                    },
                    ai: {
                        accessToTheTool: true,
                        aiCredits: 750,
                    },
                    onSiteSeoAudit: {
                        crawlablePagesPerMonth: 50000,
                        pagesCanBeAnalyzedByProject: 5000,
                        dataRetention: '3 Month'
                    },
                    keywordResearch: {
                        resultsPerKeyword: 10000,
                        keywordPerDomainPerMonth: 20000,
                        keywordList: 50
                    },
                    backlink: {
                        accessToTheTool: true,
                        backlinkRowsPerMonth: 100000,
                        analisysPerMonth: 400,
                    },
                    positionMonitoring: {
                        trackedKeyword: 1000,
                        updateFrequency: '7 Days',
                        searchEngines: 'Google / Yahoo! Bing / Yandex',
                        mobileRanking: true,
                    },
                    report: {
                        exportCsvPerDay: 100,
                        dowloadableReport: 50,
                        whiteLabelSeoReports: true,
                    },
                    leadGeneration: {
                        formAllowed: 'Infinity',
                        leadIncludedPerMonth: 50,
                        leadGenWhitelabelReports: true,
                    }
                },],
                textualTarget: "for.growing.seo.and.ppc.agencies",
                textualFeatures: [
                    "5.users.included",
                    "15.seo.projects",
                    "50.white.label.reports",
                    ""
                ]
            },
            {
                name: 'Premium',
                type: 'Agency',
                runningOut: false,
                annualInsteadAmount: 0,
                monthlyInsteadAmount: 0,
                annualAmount: 96,
                monthlyAmount: 144,
                annualAmountSavedForYear: 576,
                monthlyAmountSavedForYear: 0,
                trialPeriodDays: 7,
                annualAlias: 'plan-premium-annual',
                monthlyAlias: 'plan-premium-monthly',
                features: [{
                    platform: {
                        users: 10,
                        projects: 50
                    },
                    seoAnalysis: {
                        analisysPerDay: 2000,
                        onPageSeoCheck: true,
                        keywordReasearch: true,
                        contentAnalysis: true,
                        serpCompetitorAnalysis: true,
                    },
                    seoCopywritingAssistant: {
                        accessToTheTool: true,
                        suggestionsWithAI: true,
                        contentGeneration: true,
                    },
                    ai: {
                        accessToTheTool: true,
                        aiCredits: 1500,
                    },
                    onSiteSeoAudit: {
                        crawlablePagesPerMonth: 500000,
                        pagesCanBeAnalyzedByProject: 10000,
                        dataRetention: '6 Months'
                    },
                    keywordResearch: {
                        resultsPerKeyword: 25000,
                        keywordPerDomainPerMonth: 50000,
                        keywordList: 100
                    },
                    backlink: {
                        accessToTheTool: true,
                        backlinkRowsPerMonth: 250000,
                        analisysPerMonth: 1000,
                    },
                    positionMonitoring: {
                        trackedKeyword: 2500,
                        updateFrequency: '5 Days',
                        searchEngines: 'Google / Yahoo! Bing / Yandex',
                        mobileRanking: true,
                    },
                    report: {
                        exportCsvPerDay: 250,
                        dowloadableReport: 150,
                        whiteLabelSeoReports: true,
                    },
                    leadGeneration: {
                        formAllowed: 'Infinity',
                        leadIncludedPerMonth: 200,
                        leadGenWhitelabelReports: true,
                    }
                },],
                textualTarget: "for.large.seo.and.ppc.agencies",
                textualFeatures: [
                    "all.the.advanced.features",
                    "10.users.included",
                    "50.seo.projects",
                    "150.white.label.reports"
                ]
            },
            {
                name: 'Infinity',
                type: 'Agency',
                runningOut: false,
                annualInsteadAmount: 0,
                monthlyInsteadAmount: 0,
                annualAmount: 296,
                monthlyAmount: 444,
                annualAmountSavedForYear: 1776,
                monthlyAmountSavedForYear: 0,
                trialPeriodDays: 7,
                annualAlias: 'plan-infinity-annual',
                monthlyAlias: 'plan-infinity-monthly',
                features: [{
                    platform: {
                        users: 25,
                        projects: 'Infinity'
                    },
                    seoAnalysis: {
                        analisysPerDay: 'Infinity',
                        onPageSeoCheck: true,
                        keywordReasearch: true,
                        contentAnalysis: true,
                        serpCompetitorAnalysis: true,
                    },
                    seoCopywritingAssistant: {
                        accessToTheTool: true,
                        suggestionsWithAI: true,
                        contentGeneration: true,
                    },
                    ai: {
                        accessToTheTool: true,
                        aiCredits: 4500,
                    },
                    onSiteSeoAudit: {
                        crawlablePagesPerMonth: 1250000,
                        pagesCanBeAnalyzedByProject: 25000,
                        dataRetention: '12 Months'
                    },
                    keywordResearch: {
                        resultsPerKeyword: 50000,
                        keywordPerDomainPerMonth: 150000,
                        keywordList: 'Infinity'
                    },
                    backlink: {
                        accessToTheTool: true,
                        backlinkRowsPerMonth: 1000000,
                        analisysPerMonth: 3000,
                    },
                    positionMonitoring: {
                        trackedKeyword: 5000,
                        updateFrequency: '3 Days',
                        searchEngines: 'Google / Yahoo! Bing / Yandex',
                        mobileRanking: true,
                    },
                    report: {
                        exportCsvPerDay: 'Infinity',
                        dowloadableReport: 'Infinity',
                        whiteLabelSeoReports: true,
                    },
                    leadGeneration: {
                        formAllowed: 'Infinity',
                        leadIncludedPerMonth: 'Infinity',
                        leadGenWhitelabelReports: true,
                    }
                },],
                textualTarget: "for.highly.structured.marketing.agencies",
                textualFeatures: [
                    "all.the.premium.features",
                    "25.users.included",
                    "infinite.seo.projects",
                    "infinite.whitelabel.reports"
                ]
            },
        ];

        return {
            getAll: getAll,
            getPlansByType: getPlansByType,
            addFeaturesDetail: addFeaturesDetail,
            isPromoTime: isPromoTime,
            getCurrentPromo: getCurrentPromo,
            setPromoTimeToFalse: setPromoTimeToFalse
        };

        /**
         * @param plans
         * @param type
         * @returns {*}
         */
        function addFeaturesDetail(plans, type) {
            plans.map(function (plan) {
                plan.features = [];
                switch (plan.name) {
                    case 'Plan Lite':
                        plan.features = [{
                            text: 'access.seo.projects',
                            value: 1
                        },
                            {
                                text: 'access.analysis.per.day',
                                value: 25
                            },
                            {
                                text: 'access.monitored.keywords',
                                value: 25
                            },
                            {
                                text: 'access.scan.resources',
                                value: 250
                            },
                            {
                                text: 'access.backlink.analysis',
                                value: false,
                                showEver: true
                            },
                            {
                                text: 'access.whitelabel.reporting',
                                value: false,
                                showEver: true
                            },
                        ];
                        return;
                    case 'Plan Pro':
                        if (type === 'personal' || !type) {
                            plan.features = [{
                                text: 'access.seo.projects',
                                value: 2
                            },
                                {
                                    text: 'access.analysis.per.day',
                                    value: 100
                                },
                                {
                                    text: 'access.monitored.keywords',
                                    value: 150
                                },
                                {
                                    text: 'access.scan.resources',
                                    value: 2500
                                },
                                {
                                    text: 'access.downloadable.backlinks',
                                    value: 25000,
                                    showEver: true
                                },
                                {
                                    text: 'access.whitelabel.reporting',
                                    value: false,
                                    showEver: true
                                },

                            ];
                        } else if (type === 'personal pro') {
                            plan.features = [{
                                text: 'access.seo.projects',
                                value: 2
                            },
                                {
                                    text: 'access.analysis.per.day',
                                    value: 100
                                },
                                {
                                    text: 'access.monitored.keywords',
                                    value: 150
                                },
                                {
                                    text: 'access.scan.resources',
                                    value: 2500
                                },
                                {
                                    text: 'access.downloadable.backlinks',
                                    value: 25000,
                                },
                                {
                                    text: 'access.whitelabel.reporting',
                                    value: false,
                                },
                            ];
                        }
                        return;
                    case 'Plan Plus':
                        plan.features = [{
                            text: 'access.seo.projects',
                            value: 5,
                        },
                            {
                                text: 'access.analysis.per.day',
                                value: 200
                            },
                            {
                                text: 'access.monitored.keywords',
                                value: 500
                            },
                            {
                                text: 'access.scan.resources',
                                value: 10000
                            },
                            {
                                text: 'access.downloadable.backlinks',
                                value: 50000
                            },
                            {
                                text: 'access.whitelabel.reporting',
                                value: 25
                            },
                        ];
                        return;
                    case 'Plan Advanced':
                        plan.features = [{
                            text: 'access.users.included',
                            value: 5
                        },
                            {
                                text: 'access.seo.projects',
                                value: 15,
                            },
                            {
                                text: 'access.monitored.keywords',
                                value: 1000
                            },
                            {
                                text: 'access.scan.resources',
                                value: 50000
                            },
                            {
                                text: 'access.downloadable.backlinks',
                                value: 100000
                            },
                            {
                                text: 'access.whitelabel.reporting',
                                value: 50
                            },
                        ];
                        return;
                    case 'Plan Premium':
                        plan.features = [{
                            text: 'access.users.included',
                            value: 10
                        },
                            {
                                text: 'access.seo.projects',
                                value: 50
                            },
                            {
                                text: 'access.monitored.keywords',
                                value: 2500
                            },
                            {
                                text: 'access.scan.resources',
                                value: 500000
                            },
                            {
                                text: 'access.downloadable.backlinks',
                                value: 250000
                            },
                            {
                                text: 'access.whitelabel.reporting',
                                value: 150
                            },
                        ];
                        return;
                }
                return plan;
            });

            return plans;
        }

        /**
         * @returns {*[]|*}
         */
        function getAll() {
            if (isPromoTime() && getCurrentPromo().promoType === 'all-platform-promo') {
                return self.plans.map(function (plan) {
                    if (self.promoPlans[plan.name]) {
                        plan.annualAmount = self.promoPlans[plan.name].annualAmount;
                        plan.annualInsteadAmount = self.promoPlans[plan.name].annualInsteadAmount;

                        plan.monthlyAmount = self.promoPlans[plan.name].monthlyAmount;
                        plan.monthlyInsteadAmount = self.promoPlans[plan.name].monthlyInsteadAmount;

                        plan.annualAmountSavedForYear = self.promoPlans[plan.name].annualAmountSavedForYear;
                        plan.monthlyAmountSavedForYear = self.promoPlans[plan.name].monthlyAmountSavedForYear;
                    }
                    return plan;
                });
            }
            return self.plans;
        }

        /**
         * @returns {*[]|*}
         */
        function getPlansByType(type) {
            return getAll().filter(function (plan) {
                return plan.type === type;
            });
        }

        function isPromoTime() {
            var now = Date.now();
            return self.promoStack.filter(function (promo) {
                return now >= promo.startPromoDate && now < promo.endPromoDate;
            }).length > 0;
        }

        function setPromoTimeToFalse() {
            self.isPromoTime = false;
        }

        function getCurrentPromo() {
            var now = Date.now();
            return self.promoStack.filter(function (promo) {
                return now >= promo.startPromoDate && now < promo.endPromoDate;
            })[0];
        }
    }
});
