'use strict';
define([
    'angular',
    'modules/dashboard/components/shortcut-box/shortcut-box.component'
],function(angular, shortcutBox){
    var app=angular.module('app.dashboard.ui-components', []);
   
    app.component('shortcutBox', shortcutBox);
    return app;
});
