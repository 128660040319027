import { Document, HeadingLevel, ImageRun, Packer, Paragraph, TextRun, AlignmentType, ExternalHyperlink } from 'docx';
import app from "../../app";

app.service('DocxService', DocxService);
DocxService.$inject = ['TextStatistics', '$q', '$translate', 'KeywordDensityService'];

export default function DocxService() {

    function getImageDimensionsFromEditor(src, width, height) {
        if (width && height) {
            return { width, height };
        }
        const img = document.querySelector(`img[src="${src}"]`);
        if (img) {
            return {
                width: img.width || img.naturalWidth,
                height: img.height || img.naturalHeight,
            };
        }
        return { width: 600, height: 400 };
    }

    async function fetchImageAsBlob(url) {
        const response = await fetch(url);
        const blob = await response.blob();
        return blob;
    }

    function mapHeadingLevel(level) {
        switch (level) {
            case 1: return HeadingLevel.HEADING_1;
            case 2: return HeadingLevel.HEADING_2;
            case 3: return HeadingLevel.HEADING_3;
            case 4: return HeadingLevel.HEADING_4;
            case 5: return HeadingLevel.HEADING_5;
            case 6: return HeadingLevel.HEADING_6;
            default: return HeadingLevel.HEADING_1;
        }
    }

    function mapInlineFormatting(inlineNode) {
        if (inlineNode.type === 'text') {
            return new TextRun({
                text: inlineNode.text,
                bold: inlineNode.marks?.some((mark) => mark.type === 'bold'),
                italics: inlineNode.marks?.some((mark) => mark.type === 'italic'),
                strike: inlineNode.marks?.some((mark) => mark.type === 'strike'),
                underline: inlineNode.marks?.some((mark) => mark.type === 'underline'),
                superscript: inlineNode.marks?.some((mark) => mark.type === 'superscript'),
                subscript: inlineNode.marks?.some((mark) => mark.type === 'subscript'),
                highlight: inlineNode.marks?.some((mark) => mark.type === 'highlight') ? 'yellow' : undefined,
                color: inlineNode.marks?.find((mark) => mark.type === 'textColor')?.attrs?.color,
            });
        } else if (inlineNode.type === 'hardBreak') {
            return new TextRun({ text: '\n' });
        }
        return new TextRun({ text: '' });
    }

    function mapLink(node) {
        return new ExternalHyperlink({
            children: [new TextRun({ text: node.content[0].text, style: "Hyperlink" })],
            link: node.attrs.href
        });
    }

    async function mapContentToDocx(content) {
        const elements = [];

        for (const node of content) {
            if (node.type === 'heading') {
                elements.push(new Paragraph({
                    children: node.content.map(child => mapInlineFormatting(child)),
                    heading: mapHeadingLevel(node.attrs.level),
                }));
            } else if (node.type === 'paragraph') {
                if (node.content && node.content.length > 0) {
                    elements.push(new Paragraph({
                        children: node.content.map(child => {
                            if (child.type === 'text' || child.type === 'hardBreak') {
                                return mapInlineFormatting(child);
                            } else if (child.type === 'link') {
                                return mapLink(child);
                            }
                            return new TextRun({ text: '' });
                        }),
                    }));
                } else {
                    elements.push(new Paragraph({})); // Empty paragraph
                }
            } else if (node.type === 'bulletList') {
                elements.push(...mapList(node, 'bullet', 0));
            } else if (node.type === 'orderedList') {
                elements.push(...mapList(node, 'number', 0));
            } else if (node.type === 'image') {
                const { src, width, height } = node.attrs;
                const dimensions = getImageDimensionsFromEditor(src, width, height);
                const imageRun = new ImageRun({
                    data: await fetchImageAsBlob(src),
                    transformation: dimensions,
                });
                elements.push(new Paragraph({ children: [imageRun] }));
            } else if (node.type === 'blockquote') {
                elements.push(...mapBlockquote(node));
            }
        }

        return elements;
    }

    function mapList(node, listType, level) {
        return node.content.flatMap(item => mapListItem(item, listType, level));
    }

    function mapListItem(item, listType, level) {
        return item.content.flatMap(content => {
            if (content.type === 'paragraph') {
                return new Paragraph({
                    children: content.content.map(inlineNode => mapInlineFormatting(inlineNode)),
                    numbering: {
                        reference: listType,
                        level: level,
                    },
                });
            } else if (content.type === 'bulletList') {
                return mapList(content, 'bullet', level + 1);
            } else if (content.type === 'orderedList') {
                return mapList(content, 'number', level + 1);
            }
            return [];
        });
    }

    function mapBlockquote(node) {
        return node.content.map(paragraph =>
            new Paragraph({
                children: [
                    new TextRun("» "),
                    ...paragraph.content.map(child => mapInlineFormatting(child))
                ],
                indent: { left: 720 },
                spacing: { line: 276 },
            })
        );
    }

    async function getDocxFromTipTapJson(content) {
        const doc = new Document({
            sections: [{
                children: await mapContentToDocx(content.content),
            }],
            styles: {
                paragraphStyles: [
                    {
                        id: "Blockquote",
                        name: "Blockquote",
                        basedOn: "Normal",
                        next: "Normal",
                        quickFormat: true,
                        run: {
                            color: "999999",
                            italics: true,
                        },
                        paragraph: {
                            indent: { left: 720 },
                            spacing: { line: 276 },
                        },
                    },
                ],
            },
            numbering: {
                config: [
                    {
                        reference: "bullet",
                        levels: [0, 1, 2].map(level => ({
                            level,
                            format: "bullet",
                            text: level === 0 ? "•" : level === 1 ? "○" : "▪",
                            alignment: AlignmentType.LEFT,
                            style: {
                                paragraph: {
                                    indent: { left: 720 * (level + 1), hanging: 360 },
                                },
                            },
                        })),
                    },
                    {
                        reference: "number",
                        levels: [0, 1, 2].map(level => ({
                            level,
                            format: level === 0 ? "decimal" : level === 1 ? "lowerLetter" : "lowerRoman",
                            text: "%1.",
                            alignment: AlignmentType.LEFT,
                            style: {
                                paragraph: {
                                    indent: { left: 720 * (level + 1), hanging: 360 },
                                },
                            },
                        })),
                    },
                ],
            },
        });

        return Packer.toBlob(doc);
    }

    return {
        getDocxFromTipTapJson: getDocxFromTipTapJson
    };
}
