'use strict';
define([], function () {
    function Controller(__env, $scope, $rootScope, $state, $stateParams, userInfo, TrackEventService, $mdMedia, LanguageService, PlanDetailsService, $window, UtilitiesService, $location, CRMService, AuthService, UserService) {
        var vm = $scope;
        vm.$mdMedia = $mdMedia;
        vm.filterPlansByActiveType = filterPlansByActiveType;
        vm.switchBillingType = switchBillingType;

        vm.planTypes = [
            {
                label: 'Personal',
                value: 'Personal'
            },
            {
                label: 'Agency',
                value: 'Agency'
            },
            {
                label: 'Enterprise',
                value: 'Enterprise'
            }
        ];
        vm.selectPlanOptions = ['Personal', 'Agency', 'Enterprise'];

        vm.isPromoTime = PlanDetailsService.isPromoTime() && PlanDetailsService.getCurrentPromo().promoType == 'all-platform-promo';

        if (vm.isPromoTime) {
            applyPromoChanges();
            vm.currentPromo = PlanDetailsService.getCurrentPromo();
            vm.currentPromoModifierClass = PlanDetailsService.getCurrentPromo().currentPromoModifierClass;
        }

        if ($stateParams.type && vm.selectPlanOptions.indexOf(capitalize($stateParams.type)) >= 0) {
            vm.activePlansType = capitalize($stateParams.type);
        } else {
            vm.activePlansType = 'Agency';
        }

        if ($stateParams.periods && ($stateParams.periods.toLowerCase() == 'annually' || $stateParams.periods.toLowerCase() == 'monthly')) {
            vm.billingType = $stateParams.periods.toLowerCase();
            //vm.switchBillingType($stateParams.periods.toLowerCase());
        } else {
            vm.billingType = 'annually';
        }

        TrackEventService.event('Funnel Events', 'Entered Pricing', 'Suite');
        TrackEventService.gaV2Event('screen_view', {
            screen_name: 'pricing-suite',
        });

        if ($stateParams.dealId) {
            checkOnLocalStorage('dealId', $stateParams.dealId);
        }

        if ($stateParams.saleOwner) {
            checkOnLocalStorage('saleOwner', $stateParams.saleOwner);
        }

        vm.faqs = UtilitiesService.getFaqs();
        vm.pricingTitle = 'pricing';

        vm.selectedLang = LanguageService.getCodeAndCountrySaveOnStorage();
        vm.plans = PlanDetailsService.getPlansByType(vm.activePlansType);

        vm.canDoTrial = function () {
            if (!userInfo || userInfo.account === 'offline') {
                return true;
            } else {
                return userInfo.canDoTrial;
            }
        };

        vm.goToCheckout = function (plan) {
            var coupon = '';
            var planAlias = plan.annualAlias;
            if (vm.billingType === 'monthly') {
                planAlias = plan.monthlyAlias;
            }

            if (vm.isPromoTime) {
                coupon = PlanDetailsService.getCurrentPromo().promoCoupon;
            }

            $state.go('upgrade.checkout', {alias: planAlias, coupon: coupon});
        };

        vm.goToExternalContactPage = function () {
            var language = LanguageService.getCodeAndCountrySaveOnStorage();

            switch (language) {
                case 'it-IT':
                    $window.open("https://it.seotesteronline.com/contatti/", '_blank');
                    break;
                case 'es-ES':
                    $window.open("https://es.seotesteronline.com/contacts/", '_blank');
                    break;
                case 'pl-PL':
                    $window.open("https://pl.seotesteronline.com/contacts/", '_blank');
                    break;
                default:
                    $window.open("https://www.seotesteronline.com/contacts/", '_blank');
            }
        };

        vm.goToExternalApplyNowPage = function () {
            var language = LanguageService.getCodeAndCountrySaveOnStorage();

            switch (language) {
                case 'it-IT':
                    $window.open("https://seotesteronline.typeform.com/to/y6vbtYWd", '_blank');
                    break;
                case 'es-ES':
                    $window.open("https://seotesteronline.typeform.com/to/x5Dvl62o", '_blank');
                    break;
                default:
                    $window.open("https://seotesteronline.typeform.com/to/x5Dvl62o", '_blank');
            }
        };

        vm.switchLang = function (language) {
            vm.selectedLang = language;
            $rootScope.setLang(language);

            if (!AuthService.isAuthenticated()) {
                return;
            }

            UserService.updateProfile({locale: language})
                .catch(function (err) {
                    console.log(err);
                });
        };

        function switchBillingType(billingType) {
            vm.billingType = billingType;
            vm.filterPlansByActiveType();
        }

        function filterPlansByActiveType() {
            vm.plans = PlanDetailsService.getPlansByType(vm.activePlansType);
            if (vm.activePlansType === 'Enterprise') {
                vm.pricingTitle = 'enterprise.solutions';
            } else {
                vm.pricingTitle = 'pricing';
            }

            animatePrices();
        }

        vm.gotoElement = function (eID) {
            // set the location.hash to the id of
            // the element you wish to scroll to.
            $location.hash(eID);

            // call $anchorScroll()
            UtilitiesService.scrollTo(eID, 'pricing-container');
        };

        /**
         * Retrieve if exist the value of Deal Id
         * otherwise we set on local Storage new Deal ID
         * @param {string} key
         * @param {string} value
         */
        function checkOnLocalStorage(key, value) {
            // If key exist on url we check if it same on localstorage
            // if not same update it
            var valueSaved = $window.localStorage.getItem(key);

            if (valueSaved) {
                $window.localStorage.removeItem(key);
                $window.localStorage.setItem(key, value);
            } else {
                // If not exist we add new value on local storage
                $window.localStorage.setItem(key, value);
            }
        }

        function applyPromoChanges() {
            // on black friday promotion there are no enterprise plans
            vm.planTypes = [
                {
                    label: 'Personal',
                    value: 'Personal'
                },
                {
                    label: 'Agency',
                    value: 'Agency'
                }
            ];

            vm.selectPlanOptions = ['Personal', 'Agency'];
        }

        function animateValue(elID, start, end, duration) {
            var obj = document.getElementById(elID);

            if (!obj) {
                return;
            }
            var startTimestamp = null;
            var step = function (timestamp) {
                if (!startTimestamp) {
                    startTimestamp = timestamp;
                }
                var progress = Math.min((timestamp - startTimestamp) / duration, 1);
                obj.innerHTML = Math.floor(progress * (end - start) + start);
                if (progress < 1) {
                    window.requestAnimationFrame(step);
                }
            };
            window.requestAnimationFrame(step);
        }

        function animatePrices() {
            var animationDuration = 700;

            if (!vm.isPromoTime) {
                animateValue('price-1', vm.billingType != 'annually' ? vm.plans[0].annualAmount : vm.plans[0].monthlyAmount, vm.billingType != 'annually' ? vm.plans[0].monthlyAmount : vm.plans[0].annualAmount, animationDuration);
                animateValue('price-2', vm.billingType != 'annually' ? vm.plans[1].annualAmount : vm.plans[1].monthlyAmount, vm.billingType != 'annually' ? vm.plans[1].monthlyAmount : vm.plans[1].annualAmount, animationDuration);
                animateValue('price-3', vm.billingType != 'annually' ? vm.plans[2].annualAmount : vm.plans[2].monthlyAmount, vm.billingType != 'annually' ? vm.plans[2].monthlyAmount : vm.plans[2].annualAmount, animationDuration);
            } else {
                animateValue('promo-price-1', vm.billingType != 'annually' ? vm.plans[0].annualAmount : vm.plans[0].monthlyAmount, vm.billingType != 'annually' ? vm.plans[0].monthlyAmount : vm.plans[0].annualAmount, animationDuration);
                animateValue('promo-price-2', vm.billingType != 'annually' ? vm.plans[1].annualAmount : vm.plans[1].monthlyAmount, vm.billingType != 'annually' ? vm.plans[1].monthlyAmount : vm.plans[1].annualAmount, animationDuration);
                animateValue('promo-price-3', vm.billingType != 'annually' ? vm.plans[2].annualAmount : vm.plans[2].monthlyAmount, vm.billingType != 'annually' ? vm.plans[2].monthlyAmount : vm.plans[2].annualAmount, animationDuration);
            }
        }

        function capitalize(string) {
            return string[0].toUpperCase() + string.substring(1);
        }

        if (AuthService.isAuthenticated() && vm.canDoTrial()) {
            CRMService.addEvents(['UserToTrialPricingRecoverEvent']);
        }
    }

    Controller.$inject = ['__env', '$scope', '$rootScope', '$state', '$stateParams', 'userInfo', 'TrackEventService', '$mdMedia', 'LanguageService', 'PlanDetailsService', '$window', 'UtilitiesService', '$location', 'CRMService', 'AuthService', 'UserService'];
    return Controller;
});
